import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import axios from "../../api/api";
import axiosOrg from "axios";

function EditClients(props) {
  const [project, setProject] = useState({
    name: "",
    subTitle: "",
    clientInfo: "",
    services: "",
    result: "",
    resultInfo: "",
    coverImage: "",
    client: "",
    year: "",
    conceptImg: "",
    resultImg: "",
    featured: false,
  });
  const [clients, setClients] = useState([]);
  const { slug, lang } = useParams();
  const token = localStorage.getItem("token");
  const history = useHistory();
  const goBack = () => {
    history.goBack(-1);
  };
  useEffect(() => {}, [project]);
  useEffect(() => {
    axios.get("/data/clients").then((res) => {
      setClients(res.data.data.clients);
    });
  }, []);

  useEffect(() => {
    axios
      .get(`/data/projects?slug=${slug}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          lang,
        },
      })
      .then((res) => {
        const updated = res.data.data.projects[0];
        setProject(updated);
      })
      .catch((err) => console.log(err));
  }, [token]);

  const handleImgUpload = (e, key) => {
    const files = e.target.files;
    if (e.target.multiple) {
      const links = [];
      Array.from(e.target.files).forEach((img) => {
        const formData = new FormData();
        formData.append("key", "c7ff43f3ccc62bef70caff5ff9fb1bde");
        formData.append("image", img);

        axiosOrg
          .post(`https://api.imgbb.com/1/upload`, formData)
          .then((res) => {
            links.push(res.data.data.url);
            alert("Successfully uploaded");
          });
      });
      setProject({ ...project, [key]: links });
    } else {
      const formData = new FormData();
      formData.append("key", "c7ff43f3ccc62bef70caff5ff9fb1bde");
      formData.append("image", files[0]);

      axiosOrg.post(`https://api.imgbb.com/1/upload`, formData).then((res) => {
        setProject({ ...project, [key]: res.data.data.url });
        alert("Successfully uploaded");
      });
    }
  };

  const handleInputChange = (e, key) => {
    const { value, checked } = e.target;
    if (key === "client") {
      setProject({ ...project, client: { _id: value } });
    } else if (key === "year") {
      setProject({ ...project, year: +value });
    } else if (key === "featured") {
      setProject({ ...project, featured: checked });
    } else {
      setProject({ ...project, [key]: value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const update = {
      name: project.name,
      subTitle: project.subTitle,
      coverImage: project.coverImage,
      client: project.client._id,
      year: +project.year,
      concept: project.concept,
      conceptInfo: project.conceptInfo,
      conceptImg: project.conceptImg,
      services: project.services,
      result: project.result,
      resultInfo: project.resultInfo,
      resultImg: project.resultImg,
      featured: project.featured,
    };

    axios
      .patch(`/data/projects/${project._id}`, update, {
        headers: {
          Authorization: `Bearer ${token}`,
          lang,
        },
      })
      .then((res) => {
        setProject({});
        if (res.status === 200) {
          history.push("/projects");
        }
      });
  };

  const form = [
    {
      label: "Name",
      name: "name",
      value: project?.name,
    },
    {
      label: "Subtitle",
      name: "subTitle",
      value: project?.subTitle,
    },
    {
      label: "Client",
      name: "client",
      value: project?.client?._id,
      type: "select",
    },
    {
      label: "Year",
      name: "year",
      value: project?.year,
      type: "number",
    },
    {
      label: "Services",
      name: "services",
      value: project?.services,
    },
    {
      label: "Result",
      name: "result",
      value: project?.result,
    },
    {
      label: "Result Info",
      name: "resultInfo",
      value: project?.resultInfo,
    },
    {
      label: "Featured",
      name: "featured",
      value: project?.featured,
      type: "checkbox",
    },
    {
      label: "Cover Image",
      name: "coverImage",
      value: project?.coverImage,
      type: "file",
    },
    {
      name: "img",
      value: project?.coverImage,
    },
    {
      label: "Concept Image",
      name: "clientImg",
      value: project?.conceptImg,
      type: "file",
      multiple: true,
    },
    {
      name: "img",
      value: project?.conceptImg,
    },
    {
      label: "Result Image",
      name: "resultImg",
      value: project?.resultImg,
      type: "file",
      multiple: true,
    },
    {
      name: "img",
      value: project?.resultImg,
    },
  ];

  return (
    <div className="crud-page">
      <div className="title">Edit Project</div>
      <div className="form">
        {form.map((el, i) => {
          return (
            <>
              <div className="input-group" key={i}>
                {el.label && <label>{el.label}</label>}
                {el.type === "select" ? (
                  <select
                    name={el.name}
                    onChange={(e) => handleInputChange(e, el.name)}
                  >
                    {clients.map((client, i) => (
                      <option
                        selected={project?.client?._id === client._id}
                        value={client._id}
                        key={i}
                      >
                        {client.name}
                      </option>
                    ))}
                  </select>
                ) : el.name === "img" ? (
                  <img src={el.value} alt={el.name} />
                ) : el.type === "file" ? (
                  <input
                    type={el.type}
                    name={el.name}
                    multiple={el.multiple}
                    value={project[el.value]}
                    onChange={(e) => handleImgUpload(e, `${el.name}`)}
                    disabled={el.disabled}
                  />
                ) : (
                  <input
                    type={el.type || "text"}
                    name={el.name}
                    multiple={el.multiple}
                    value={el.value}
                    onChange={(e) => handleInputChange(e, `${el.name}`)}
                    disabled={el.disabled}
                  />
                )}
              </div>
            </>
          );
        })}
        <div className="btns">
          <button className="close-btn" onClick={goBack}>
            Back
          </button>
          <button className="add-btn" onClick={handleSubmit}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
}

export default EditClients;
