import { useContext, useRef } from "react";
import { Context } from "../context/Context";
import axios from "../api/api";

export default function Login() {
  const { dispatch, isFetching } = useContext(Context);
  const userRef = useRef();
  const passwordRef = useRef();

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch({ type: "LOGIN_START" });
    try {
      const res = await axios.post("/auth/login", {
        email: userRef.current.value,
        password: passwordRef.current.value,
      });
      if (res.status === 200) {
        dispatch({ type: "LOGIN_SUCCESS", payload: res.data.data.user });
        dispatch({ type: "SET_TOKEN", payload: res.data.token });
      }
    } catch (err) {
      dispatch({ type: "LOGIN_FAILURE" });
    }
  };
  return (
    <div className="login">
      <div className="form">
        <span className="login-title">Login</span>
        <form className="login-form" onSubmit={handleSubmit}>
          <div className="input-group">
            <label>Email</label>
            <input
              className="login-input"
              type="text"
              placeholder="Enter your email..."
              ref={userRef}
            />
          </div>

          <div className="input-group">
            <label>Password</label>
            <input
              className="login-input"
              type="password"
              placeholder="Enter your password..."
              ref={passwordRef}
            />
          </div>
          <button className="login-button" type="submit" disabled={isFetching}>
            Login
          </button>
        </form>
      </div>
    </div>
  );
}
