import axiosOrg from "axios";
import axios from "../../api/api";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

function AddProject() {
  const history = useHistory();
  const token = localStorage.getItem("token");

  const [clients, setClients] = useState([]);
  const [project, setProject] = useState({
    coverImage: "",
    client: "",
    year: "",
    conceptImg: "",
    resultImg: "",
    featured: false,
  });
  const [projectRu, setProjectRu] = useState({
    name: "",
    subTitle: "",
    clientInfo: "",
    services: "",
    result: "",
    resultInfo: "",
  });
  const [projectEn, setProjectEn] = useState({
    name: "",
    subTitle: "",
    clientInfo: "",
    services: "",
    result: "",
    resultInfo: "",
  });
  const goBack = () => {
    history.push("/projects");
  };

  useEffect(() => {
    console.log(project);
  }, [project]);
  useEffect(() => {
    axios.get("/data/clients").then((res) => {
      setClients(res.data.data.clients);
      setProject({ ...project, client: res.data.data.clients[0]._id });
    });
  }, []);

  const handleImgUpload = (e, key) => {
    const files = e.target.files;
    if (e.target.multiple) {
      const links = [];
      Array.from(e.target.files).forEach((img) => {
        const formData = new FormData();
        formData.append("key", "c7ff43f3ccc62bef70caff5ff9fb1bde");
        formData.append("image", img);

        axiosOrg
          .post(`https://api.imgbb.com/1/upload`, formData)
          .then((res) => {
            links.push(res.data.data.url);
            alert("Successfully uploaded");
          });
      });
      setProject({ ...project, [key]: links });
    } else {
      const formData = new FormData();
      formData.append("key", "c7ff43f3ccc62bef70caff5ff9fb1bde");
      formData.append("image", files[0]);

      axiosOrg.post(`https://api.imgbb.com/1/upload`, formData).then((res) => {
        setProject({ ...project, [key]: res.data.data.url });
        alert("Successfully uploaded");
      });
    }
  };

  const handleInputChange = (e, key, label) => {
    if (label) {
      const array = label?.split(" ");
      var lang = array[array.length - 1];
    }

    const { value, checked } = e.target;
    if (key === "client") {
      setProject({ ...project, client: { _id: value } });
    } else if (key === "year") {
      setProject({ ...project, year: +value });
    } else if (key === "featured") {
      setProject({ ...project, featured: checked });
    } else if (lang === "Ru") {
      setProjectRu({ ...projectRu, [key]: value });
    } else if (lang === "En") {
      setProjectEn({ ...projectEn, [key]: value });
    } else {
      setProject({ ...project, [key]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const en = await axios.post(
      `/data/projects`,
      {
        ...project,
        ...projectEn,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          lang: "en",
        },
      }
    );
    const ru = await axios.post(
      `/data/projects`,
      {
        ...project,
        ...projectRu,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          lang: "ru",
        },
      }
    );
    if (ru.data.status === "success" && en.data.status === "success") {
      history.push("/projects");
    }
    setProject({});
    setProjectEn({});
    setProjectRu({});
  };

  const form = [
    {
      label: "Name En",
      name: "name",
      value: projectEn.name,
    },
    {
      label: "Name Ru",
      name: "name",
      value: projectRu.name,
    },
    {
      label: "Subtitle En",
      name: "subTitle",
      value: projectEn.subTitle,
    },
    {
      label: "Subtitle Ru",
      name: "subTitle",
      value: projectRu.subTitle,
    },
    {
      label: "Client Info En",
      name: "clientInfo",
      value: projectEn.clientInfo,
    },
    {
      label: "Client Info Ru",
      name: "clientInfo",
      value: projectRu.clientInfo,
    },
    {
      label: "Services En",
      name: "services",
      value: projectEn.services,
    },
    {
      label: "Services Ru",
      name: "services",
      value: projectRu.services,
    },
    {
      label: "Result En",
      name: "result",
      value: projectEn.result,
    },
    {
      label: "Result Ru",
      name: "result",
      value: projectRu.result,
    },
    {
      label: "Result Info En",
      name: "resultInfo",
      value: projectEn.resultInfo,
    },
    {
      label: "Result Info Ru",
      name: "resultInfo",
      value: projectRu.resultInfo,
    },
    {
      label: "Cover Image",
      name: "coverImage",
      value: project.coverImage,
      type: "file",
    },
    {
      label: "Client",
      name: "client",
      value: project.client?._id,
      type: "select",
    },
    {
      label: "Year",
      name: "year",
      value: project?.year,
    },
    {
      label: "Concept Image",
      name: "conceptImg",
      value: project.conceptImg,
      type: "file",
      multiple: true,
    },
    {
      label: "Result Image",
      name: "resultImg",
      value: project.resultImg,
      type: "file",
      multiple: true,
    },
    {
      label: "Featured",
      name: "featured",
      value: project.featured,
      type: "checkbox",
    },
  ];

  return (
    <div className="crud-page">
      <div className="title">Add project</div>
      <div className="form">
        {form.map((el, i) => {
          return (
            <div className="input-group" key={i}>
              <label>{el.label}</label>
              {el.type === "select" ? (
                <select
                  name={el.name}
                  onChange={(e) => handleInputChange(e, `${el.name}`)}
                >
                  {clients.map((client, i) => (
                    <option value={client._id} key={i}>
                      {client.name}
                    </option>
                  ))}
                </select>
              ) : (
                <input
                  type={el.type || "text"}
                  name={el.name}
                  multiple={el.multiple}
                  value={project[el.value]}
                  onChange={(e) =>
                    el.type === "file"
                      ? handleImgUpload(e, el.name)
                      : handleInputChange(e, el.name, el.label)
                  }
                />
              )}
            </div>
          );
        })}
        <div className="btns">
          <button className="close-btn" onClick={goBack}>
            Back
          </button>
          <button className="add-btn" onClick={handleSubmit}>
            Add
          </button>
        </div>
      </div>
    </div>
  );
}

export default AddProject;
