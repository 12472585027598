import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { ContextProvider } from "./context/Context";
import "./assets/css/grid.css";
import "./assets/css/index.css";
import { HashRouter } from "react-router-dom";

ReactDOM.render(
  <React.StrictMode>
    <ContextProvider>
      <HashRouter>
        <App />
      </HashRouter>
    </ContextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
