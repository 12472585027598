import React, { useEffect, useState } from "react";
import axiosOrg from "axios";
import { useHistory } from "react-router-dom";
import axios from "../../api/api";
function EditClients(props) {
  const [client, setClient] = useState({
    _id: "",
    name: "",
    logo: "",
    logoFile: "",
    link: "",
    slug: "",
  });

  const token = localStorage.getItem("token");
  const history = useHistory();
  const goBack = () => {
    history.goBack(-1);
  };

  useEffect(() => {
    axios
      .get(`/data/clients/${props.match.params.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        const updated = res.data.data.client;
        updated.logoFile = "";
        setClient(updated);
      })
      .catch((err) => console.log(err));
  }, [props.match.params.id, token]);

  const hanndleInputChage = (e, key) => {
    if (key === "logo") {
      const formData = new FormData();
      formData.append("key", "c7ff43f3ccc62bef70caff5ff9fb1bde");
      formData.append("image", e.target.files[0]);

      axiosOrg.post(`https://api.imgbb.com/1/upload`, formData).then((res) => {
        const logo = res.data.data.url;
        setClient({ ...client, logo: logo });
      });
      return;
    }

    const { value } = e.target;
    setClient({ ...client, [key]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const update = {
      name: client.name,
      logo: client.logo,
      link: client.link,
      slug: client.slug,
    };

    axios
      .patch(`/data/clients/${props.match.params.id}`, update, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setClient({});
        if (res.status === 200) {
          history.push("/clients");
        }
      });
  };

  const form = [
    {
      label: "Id",
      name: "Id",
      value: client._id,
      disabled: true,
    },
    {
      label: "Name",
      name: "name",
      value: client?.name,
    },
    {
      label: "Logo",
      name: "logo",
      value: client?.logoFile,
      type: "file",
    },
    {
      label: "Link",
      name: "link",
      value: client?.link,
    },
    {
      name: "img",
      type: "img",
    },
    {
      label: "Slug",
      name: "slug",
      value: client?.slug,
    },
  ];

  return (
    <div className="crud-page">
      <div className="title">Edit client</div>
      <div className="form">
        {form.map((el, i) => {
          return (
            <div className="input-group" key={i}>
              <label>{el.label}</label>
              {el.type === "img" ? (
                <img src={client?.logo} alt={client?.name} />
              ) : (
                <input
                  type={el.type || "text"}
                  name={el.name}
                  value={el.value}
                  onChange={(e) => hanndleInputChage(e, `${el.name}`)}
                  disabled={el.dis}
                />
              )}
            </div>
          );
        })}
        <div className="btns">
          <button className="close-btn" onClick={goBack}>
            Back
          </button>
          <button className="add-btn" onClick={handleSubmit}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
}

export default EditClients;
