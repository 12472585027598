import React from "react";
import "./switch.css";
function SwitchLang({ setLang }) {
  return (
    <div className="switch">
      <label className="toggle" htmlFor="uniqueID">
        <span className="lang">Ru</span>
        <input
          type="checkbox"
          className="toggle__input"
          id="uniqueID"
          onChange={(e) => {
            e.target.checked ? setLang("en") : setLang("ru");
          }}
        />
        <span className="toggle-track">
          <span className="toggle-indicator">{/* <span className="checkMark">ru</span> */}</span>
        </span>
        <span className="lang">En</span>
      </label>
    </div>
  );
}

export default SwitchLang;
