import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Projects from "./pages/Projects";
import Clients from "./pages/Clients";
import UserRoute from "./UserRoute";
export const userRoutes = [
  {
    title: "Dashboard",
    path: "/",
    component: Dashboard,
  },
  {
    title: "Projects",
    path: "/projects",
    component: Projects,
  },
  {
    title: "Clients",
    path: "/clients",
    component: Clients,
  },
];
function Routes() {
  return (
    <Switch>
      <Route exact path="/login" render={() => <Redirect to="/" />} />
      {userRoutes.map((r, i) => (
        <UserRoute exact path={r.path} key={i} component={r.component} />
      ))}
    </Switch>
  );
}

export default Routes;
