import React from "react";
import { Link, useLocation } from "react-router-dom";
import { userRoutes } from "../../Routes";

import "./sidebar.css";

import logo from "../../assets/images/logo.png";

const SidebarItem = (props) => {
  const active = props.active ? "active" : "";

  return (
    <div className="sidebar__item">
      <div className={`sidebar__item-inner ${active}`}>
        {/* <i className={props.icon}></i> */}
        <span>{props.title}</span>
      </div>
    </div>
  );
};

const Sidebar = () => {
  const loc = useLocation();
  const activeItem = userRoutes.findIndex((item) => item.path === loc.pathname);
  return (
    <div className="sidebar">
      <div className="sidebar__logo">
        <img src={logo} alt="company logo" />
      </div>
      {userRoutes.map((item, index) => (
        <Link to={item.path} key={index}>
          <SidebarItem
            title={item.title}
            // icon={item.icon}
            active={index === activeItem}
          />
        </Link>
      ))}
    </div>
  );
};

export default Sidebar;
