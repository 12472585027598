import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import editIcon from "../../assets/images/icons/edit.png";
import deleteIcon from "../../assets/images/icons/delete.png";
import axios from "../../api/api";
import Table from "../Table/Table";
import SwitchLang from "../language/SwitchLang";

const customerTableHead = [
  "ID",
  "Name",
  "Subtitle",
  "Cover Image",
  "Client",
  "Action",
];

const renderHead = (item, index) => <th key={index}>{item}</th>;

function AlldProjects() {
  const [projects, setProjects] = useState([]);

  const token = localStorage.getItem("token");
  const [lang, setLang] = useState("ru");

  useEffect(() => {
    axios.get("/data/projects", { headers: { lang } }).then((res) => {
      setProjects(res.data.data.projects);
    });
  }, [lang]);

  const handleDelete = (id) => {
    axios
      .delete(`/data/projects/${id}`, {
        headers: { Authorization: `Bearer ${token}`, lang },
      })
      .then((res) => {
        if (res.status === 204) {
          setProjects((prev) => prev.filter((item) => id !== item._id));
        }
      })
      .catch((err) => console.log(err));
  };

  const renderBody = (item, index) => {
    return (
      <tr key={index}>
        <td>
          <input type="checkbox" />
        </td>
        <td>{index + 1}</td>
        <td>{item.name}</td>
        <td>{item.subTitle}</td>
        <td>
          <img src={item.coverImage} alt={item.name} />
        </td>
        <td>{item.client}</td>
        <td>
          <Link
            to={`/edit-project/${item.slug}/${lang}`}
            className="table_btn edit_btn"
          >
            <img src={editIcon} alt={editIcon} />
          </Link>
          <button
            className="table_btn delete_btn"
            onClick={() => handleDelete(item._id)}
          >
            <img src={deleteIcon} alt={deleteIcon} />
          </button>
        </td>
      </tr>
    );
  };

  return (
    <div>
      <h2 className="page-header">
        Projects
        <SwitchLang setLang={setLang} />
        <Link to="/add-project" className="add-btn">
          Add
        </Link>
      </h2>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card__body">
              <Table
                limit="10"
                headData={customerTableHead}
                renderHead={(item, index) => renderHead(item, index)}
                bodyData={projects}
                renderBody={(item, index) => renderBody(item, index)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AlldProjects;
