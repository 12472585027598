import { useContext } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Sidebar from "./components/sidebar/Sidebar";
import { Context } from "./context/Context";
import Clients from "./pages/Clients";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import Projects from "./pages/Projects";
import AddClient from "./components/clients/AddClient";
import "./components/layout/layout.css";
import EditClients from "./components/clients/EditClients";
import AddProject from "./components/projects/AddProject";
import EditProject from "./components/projects/EditProject";

export const userRoutes = [
  {
    title: "Dashboard",
    path: "/",
    component: Dashboard,
  },
  {
    title: "Projects",
    path: "/projects",
    component: Projects,
  },
  {
    title: "Add Project",
    path: "/add-project",
    component: AddProject,
  },
  {
    title: "Edit project",
    path: "/edit-project/:slug/:lang",
    component: EditProject,
  },
  {
    title: "Clients",
    path: "/clients",
    component: Clients,
  },
  {
    title: "Add client",
    path: "/add-client",
    component: AddClient,
  },
  {
    title: "Edit client",
    path: "/edit-client/:id",
    component: EditClients,
  },
];

function App(props) {
  const { token } = useContext(Context);

  if (token) {
    return (
      <div className="layout" style={{ backgroundColor: "#fff" }}>
        <Sidebar {...props} />
        <div className="layout__content">
          {/* <TopNav/> */}
          <div className="layout__content-main">
            <Switch>
              <Route exact path="/login" render={() => <Redirect to="/" />} />
              {userRoutes.map((r, i) => (
                <Route exact path={r.path} key={i} component={r.component} />
              ))}
            </Switch>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route path="*" render={() => <Redirect to="/login" />} />
      </Switch>
    );
  }
}

export default App;
