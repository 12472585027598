import axiosOrg from "axios";
import axios from "../../api/api";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
function AddClient() {
  const history = useHistory();
  const token = localStorage.getItem("token");
  const [client, setClient] = useState({});
  const goBack = () => {
    history.push("/clients");
  };

  const hanndleInputChage = (e, key) => {
    if (key === "logo") {
      const formData = new FormData();
      formData.append("key", "c7ff43f3ccc62bef70caff5ff9fb1bde");
      formData.append("image", e.target.files[0]);

      axiosOrg.post(`https://api.imgbb.com/1/upload`, formData).then((res) => {
        const logo = res.data.data.url;
        setClient({ ...client, logo: logo });
      });
      return;
    }

    const { value } = e.target;
    setClient({ ...client, [key]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const create = {
      name: client.name,
      logo: client.logo,
      link: client.link,
      slug: client.slug,
    };

    axios
      .post(`/data/clients`, create, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setClient({});
        if (res.status === 201) {
          history.push("/clients");
        }
      });
  };

  const form = [
    {
      label: "Name",
      name: "name",
      value: client.name,
    },
    {
      label: "Logo",
      name: "logo",
      type: "file",
      value: client.logo,
    },
    {
      label: "Link",
      name: "link",
      value: client.link,
    },
    {
      label: "Slug",
      name: "slug",
      value: client.slug,
    },
  ];

  return (
    <div className="crud-page">
      <div className="title">Add client</div>
      <div className="form">
        {form.map((el, i) => {
          return (
            <div className="input-group" key={i}>
              <label>{el.label}</label>
              <input
                type={el.type || "text"}
                name={el.name}
                value={client[el.value]}
                onChange={(e) => hanndleInputChage(e, `${el.name}`, "file")}
              />
            </div>
          );
        })}
        <div className="btns">
          <button className="close-btn" onClick={goBack}>
            Back
          </button>
          <button className="add-btn" onClick={handleSubmit}>
            Add
          </button>
        </div>
      </div>
    </div>
  );
}

export default AddClient;
