import React, { useEffect, useState } from "react";
import Table from "../../components/Table/Table";
import editIcon from "../../assets/images/icons/edit.png";
import deleteIcon from "../../assets/images/icons/delete.png";
import axios from "../../api/api";
import { Link } from "react-router-dom";
const customerTableHead = ["id", "Name", "Logo", "Link", "Action"];

const renderHead = (item, index) => <th key={index}>{item}</th>;

function AllClients() {
  const [clients, setClients] = useState([]);

  useEffect(() => {
    axios.get("/data/clients").then((res) => {
      setClients(res.data.data.clients);
    });
  }, []);

  const token = localStorage.getItem("token");

  const handleDelete = (id) => {
    axios
      .delete(`/data/clients/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        if (res.status === 204) {
          setClients((prev) => prev.filter((item) => id !== item._id));
        }
      })
      .catch((err) => console.log(err));
  };

  const renderBody = (item, index) => {
    return (
      <tr key={index}>
        <td>
          <input type="checkbox" />
        </td>
        <td>{index + 1}</td>
        <td>{item.name}</td>
        <td>
          <img src={item.logo} alt={item.name} />
        </td>
        <td>{item.link}</td>
        <td>
          <Link to={`/edit-client/${item._id}`} className="table_btn edit_btn">
            <img src={editIcon} alt={editIcon} />
          </Link>
          <button
            className="table_btn delete_btn"
            onClick={() => handleDelete(item._id)}
          >
            <img src={deleteIcon} alt={deleteIcon} />
          </button>
        </td>
      </tr>
    );
  };
  return (
    <div>
      <h2 className="page-header">
        Clients
        <Link to="/add-client" className="add-btn">
          add client
        </Link>
      </h2>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card__body">
              <Table
                limit="4"
                headData={customerTableHead}
                renderHead={(item, index) => renderHead(item, index)}
                bodyData={clients}
                renderBody={(item, index) => renderBody(item, index)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AllClients;
